import api from "@/apps/maintenance-api/api";

export default async function () {
    const gdprrequests = await api.gdprrequests.Count();

    return [
        {
            title: "Dashboard",
            route: {
                name: "maintenance-dashboard",
            },
            icon: "HomeIcon",
        },
        {
            title: `GDPR Requests`,
            tag: gdprrequests > 0 ? gdprrequests : undefined,
            route: {
                name: "maintenance-gdprrequests",
            },
            icon: "AlertOctagonIcon",
        },
        {
            title: `Client Settings`,
            route: {
                name: "maintenance-clientsettings",
            },
            icon: "AlertOctagonIcon",
        },
        {
            title: `Access Lists`,
            route: {
                name: "maintenance-accesslists",
            },
            icon: "AlertOctagonIcon",
        },
        {
            title: `Idibu Accounts`,

            route: {
                name: "maintenance-idibuaccounts",
            },
            icon: "AlertOctagonIcon",
        },
        {
            title: `Vincere Instances`,

            route: {
                name: "maintenance-vincereinstances",
            },
            icon: "AlertOctagonIcon",
        },
        {
            title: `Dynamics Instances`,

            route: {
                name: "maintenance-dynamicsinstances",
            },
            icon: "AlertOctagonIcon",
        },
        {
            title: `Platforms`,
            route: {
                name: "maintenance-platforms",
            },
            icon: "AlertOctagonIcon",
        },
        {
            title: `Roles`,
            route: {
                name: "maintenance-roles",
            },
            icon: "AlertOctagonIcon",
        },
        {
            title: "Candidate API",
            children: [
                {
                    title: `Additional Fields`,
                    route: {
                        name: "maintenance-candidate-additionalfields",
                    }
                },
            ]
        }
    ];
}
