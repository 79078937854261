export default async function () {
    return [
        {
            title: "Dashboard",
            route: {
                name: "registrations-dashboard",
            },
            icon: "HomeIcon",
        },
        {
            title: `Flows`,
            route: {
                name: "registration-flows",
            },
            icon: "AlertOctagonIcon",
        },
        {
            title: `Auto Onboarding`,
            route: {
                name: "registration-autoonboardings",
            },
            icon: "AlertOctagonIcon",
        },
        {
            title: `Surveys`,
            route: {
                name: "registration-surveys",
            },
            icon: "AlertOctagonIcon",
        },
        {
            title: `Bulk Send`,
            route: {
                name: "registration-bulksend",
            },
            icon: "AlertOctagonIcon",
        },
        {
            title: `Maintenance`,
            children: [
                {
                    title: `Assign Employee`,
                    route: {
                        name: "registration-maintenance-assignemployee",
                    },
                    icon: "AlertOctagonIcon",
                },
                {
                    title: `Status Change`,
                    route: {
                        name: "registration-maintenance-statuschange",
                    },
                    icon: "AlertOctagonIcon",
                },
                {
                    title: `Reindex`,
                    route: {
                        name: "registration-maintenance-reindex",
                    },
                    icon: "AlertOctagonIcon",
                }
            ],
            icon: "AlertOctagonIcon",
        },
    ];
}
