export default async function () {
    return [
        {
            title: "Dashboard",
            route: {
                name: "reporting-dashboard",
            },
            icon: "HomeIcon",
        },
        {
            title: `Report Generators`,
            route: {
                name: "reporting-generators",
            },
            icon: "AlertOctagonIcon",
        },
        {
            title: `Report Templates`,
            route: {
                name: "reporting-templates",
            },
            icon: "AlertOctagonIcon",
        },
        {
            title: `Reports`,
            route: {
                name: "reporting-reports",
            },
            icon: "AlertOctagonIcon",
        },
    ];
}
