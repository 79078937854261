// @ts-nocheck
import EmployeeRoutes from "./employee-api/navigation";
import CandidateRoutes from "./candidate-api/navigation";
import RegistrationsRoutes from "./registrations-api/navigation";
import MaintenanceRoutes from "./maintenance-api/navigation";
import ReportingRoutes from "./reporting-api/navigation";

let routes = [
  {
    title: "Home",
    route: "home",
    icon: "HomeIcon",
  },
];

import $store from "@/store";

export default async function () {
  switch ($store.getters["user/GET_CURRENT_APP"]) {
    case "employee":
      routes = await EmployeeRoutes();
      break;
    case "candidate":
      routes = await CandidateRoutes();
      break;
    case "registrations":
      routes = await RegistrationsRoutes();
      break;
    case "maintenance":
      routes = await MaintenanceRoutes();
      break;
    case "reporting":
      routes = await ReportingRoutes();
      break;
    default:
      break;
  }

  return routes;
}
