







import { Vue, Component, Prop } from "vue-property-decorator";
import { $themeConfig } from "@/themeConfig";
import { BImg } from "bootstrap-vue";
const { appName, appLogoImage, appLogoIcon } = $themeConfig.app;

@Component({
  components: {
    BImg,
  },
})
export default class BrandLogo extends Vue {
  @Prop({ default: () => false })
  isCollapsed: boolean;

  appLogoImage = appLogoImage;
  appLogoIcon = appLogoIcon;
}
