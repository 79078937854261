<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <feather-icon icon="GridIcon" size="21" class="cursor-pointer" @click="appSelector"></feather-icon>
      <!--<dark-Toggler class="d-none d-lg-block" />-->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ $store.getters['user/GET_USER'].firstName }} {{ $store.getters['user/GET_USER'].lastName }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          />
        </template>


        <b-dropdown-divider />
        <b-dropdown-item link-class="d-flex align-items-center" @click="appSelector">
          <feather-icon
              size="16"
              icon="ColumnsIcon"
              class="mr-50"
          />
          <span>Change App</span>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    //DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {return},
    },
  },
  methods: {
    async logout() {
      await this.$router.push({name: "logout"}).catch(err => null)
    },
    async appSelector() {
      this.$store.commit('user/SET_APP', null)
      await this.$router.push({name: 'app-selector'})
    }
  }
}
</script>

<style lang="scss">
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: 15rem;
}
</style>
