import router from "@/router";

const platform = router.currentRoute.params.platform;
export default async function () {
  return [
    {
      title: "Dashboard",
      route: {
        name: "candidate-dashboard",
        params: {
          platform,
        },
      },
      icon: "HomeIcon",
    },
    {
      title: "Candidates",
      route: {
        name: "candidate-users",
      },
      children: [
        {
          title: "View All",
          route: {
            name: "candidate-users",
          },
          icon: "UsersIcon",
        },
      ],
      icon: "UsersIcon",
    },
    {
      title: "Find",
      children: [
        {
          title: "By Acode",
          route: {
            name: "candidate-find-acode",
          }
        },
      ],
      icon: "UsersIcon",
    },
  ];
}
